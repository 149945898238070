import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { AlertService } from '../_services/index';

@Component({
  moduleId: module.id,
  // tslint:disable-next-line:component-selector
  selector: 'alert',
  template: `<div *ngIf="message"
                  class="alert-dismissible"
                  [ngClass]="{ 'alert': message, 'alert-success': message.type === 'success', 'alert-info': message.type === 'info', 'alert-warning': message.type === 'warning', 'alert-danger': message.type === 'error' }"
                  [innerHTML]="message.text"></div>`
})

export class AlertComponent implements OnDestroy {
  private subscription: Subscription;
  message: any;

  constructor(private alertService: AlertService) {
    // subscribe to alert messages
    this.subscription = alertService.getMessage().subscribe(message => { this.message = message; });
  }

  ngOnDestroy(): void {
    // unsubscribe on destroy to prevent memory leaks
    this.subscription.unsubscribe();
  }
}
