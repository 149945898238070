/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../_directives/alert.component.ngfactory";
import * as i2 from "../_directives/alert.component";
import * as i3 from "../_services/alert.service";
import * as i4 from "./calendar-client.component";
import * as i5 from "@angular/router";
import * as i6 from "../_providers/global-settings";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../_services/markdown.service";
import * as i10 from "../_services/api.service";
var styles_CalendarClientComponent = [];
var RenderType_CalendarClientComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarClientComponent, data: {} });
export { RenderType_CalendarClientComponent as RenderType_CalendarClientComponent };
export function View_CalendarClientComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "alert", [], null, null, null, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(3, 180224, null, 0, i2.AlertComponent, [i3.AlertService], null, null)], null, null); }
export function View_CalendarClientComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-calendar-client", [], null, null, null, View_CalendarClientComponent_0, RenderType_CalendarClientComponent)), i0.ɵdid(1, 4308992, null, 0, i4.CalendarClientComponent, [i5.ActivatedRoute, i6.GlobalSettings, i3.AlertService, i5.Router, i7.FormBuilder, i8.DomSanitizer, i9.MarkdownService, i10.ClientResource], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarClientComponentNgFactory = i0.ɵccf("app-calendar-client", i4.CalendarClientComponent, View_CalendarClientComponent_Host_0, {}, {}, []);
export { CalendarClientComponentNgFactory as CalendarClientComponentNgFactory };
