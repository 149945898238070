import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService, ClientResource, Settings} from '../_services';
import { GlobalSettings} from '../_providers';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import postscribe from 'postscribe';

@Component({
  selector: 'app-root',
  template: `
    <div class="modal" style="display:block; top:5%; position:absolute;" id="loginModal" *ngIf="whitelabel && whitelabel.id">

      <form *ngIf="!show_registration" class="form" name="login" (ngSubmit)="login.form.valid && getClient()" #login="ngForm" novalidate autocomplete="off">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="text-center" style="margin-bottom:20px;">
                <img src="{{whitelabel.logo}}" alt="{{whitelabel.title}}" style="max-height:200px; max-width:100%;">
              </div>
              <alert></alert>

              <p *ngIf="whitelabel.intro_body && !message" class="text-center">{{whitelabel.intro_body}}</p>
              <div id="mfa_holder"></div>
              <app-loading *ngIf="loading" ></app-loading>
            </div>
          </div>
        </div>
      </form>

      <form *ngIf="show_registration" class="form" name="registration" (ngSubmit)="registration.form.valid && registerClient()" #registration="ngForm" novalidate autocomplete="off">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="text-center" style="margin-bottom:20px;">
                <img src="{{whitelabel.logo}}" title="{{whitelabel.title}}" style="max-height:200px; max-width:100%;">
              </div>
              <h4>Registration</h4>

              <div class="form-group-xl mt-2">
                <label>Your Name</label>
                <input type="text" class="form-control" name="name" [(ngModel)]="model.name" #name="ngModel" required
                       placeholder="Full Name">
                <div *ngIf="registration.submitted && !name.valid" class="help-block">Please tell us your name</div>
              </div>

              <div class="form-group-xl mt-2">
                <label>Your Email Address</label>
                <input type="email" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel" required
                       placeholder="Email Address" autocomplete="off">
                <div *ngIf="registration.submitted && !email.valid" class="help-block">Enter your email address!</div>
              </div>

              <div class="form-group-xl mt-2">
                <label>Your Phone Number</label>
                <div class="form-control">{{model.mbvt_wave_msisdn}}</div>
              </div>

              <div *ngIf="error_message" class="help-block text-danger">{{error_message}}</div>
            </div>

            <div class="modal-footer">

              <button [disabled]="registration['$invalid']" *ngIf="!loading" class="btn btn-primary">
                <span>Register</span>
              </button>
              <app-loading *ngIf="loading"></app-loading>
            </div>
          </div>
        </div>
      </form>

      <div *ngIf="show_forbidden" class="alert alert-danger">
        <h3>Sorry</h3>
        <p>Account was not found and this system does not accept new registrations. Please call us if you like to make a booking.</p>
      </div>


    </div>
  `
})

export class LoginComponent implements OnInit, AfterViewChecked  {

  whitelabel: Settings;
  model: any = {};
  loading = false;
  returnUrl: string;
  mfa_ready = false;
  show_registration = false;
  show_forbidden = false;
  error_message: any = null;
  message: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private globalSettings: GlobalSettings,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private client: ClientResource,
  ) {
    this.whitelabel = this.globalSettings.whitelabel;
    this.globalSettings.whitelabel$.subscribe((val: Settings) => { this.whitelabel = val; });
  }

  ngOnInit() {

    // const message = JSON.parse(sessionStorage.getItem('message'));
    // if (message) {
    //   sessionStorage.removeItem('message');
    //   this.alertService[message.type](message.content);
    // }
    // reset login status
    sessionStorage.removeItem('currentAppointmentUser');

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    document.addEventListener('mbvt_mfa_ready', e => {
      this.mfa_ready = true;
      this.getClient();
    });
  }

  getClient() {
    this.loading = true;
    const msisdn = document.getElementsByName('mbvt_wave_msisdn')[0].getAttribute('value');
    const signed = document.getElementsByName('mbvt_wave_signed_msisdn')[0].getAttribute('value');

    sessionStorage.setItem('msisdn', msisdn);
    sessionStorage.setItem('signed', signed);

    this.client.exists(sessionStorage.getItem('msisdn'))
      .subscribe(results => {
        this.loading = false;
      if (results.exists) {
        this.client.get_data({mbvt_wave_msisdn: msisdn, mbvt_wave_signed_msisdn: signed})
          .subscribe(client => {
            sessionStorage.setItem('name', client.name);
            this.alertService.success( `Welcome back ${client.name}`, true);
            // sessionStorage.setItem('message', JSON.stringify({type: 'success', content: `Welcome back ${client.name}`}));
            this.globalSettings.setUser(client.id);

            this.router.navigateByUrl(this.returnUrl);

          }, error => {
            console.warn('error', error)
          });
      } else {
        if (this.whitelabel.allow_registrations) {
          this.model.mbvt_wave_msisdn = msisdn;
          this.model.mbvt_wave_signed_msisdn = signed;
          this.show_registration = true;
        } else {
          this.show_forbidden = true;
        }
      }
    });
  }

  registerClient() {
    this.loading = true;
    this.client.create(this.model).subscribe( client => {
      this.error_message = null;
      sessionStorage.setItem('name', client.name);
      this.globalSettings.setUser(client.id);

      this.alertService.success(
        `Welcome to ${this.whitelabel.title} ${client.name}. You may search for available time-slots now. See you soon.`,
        true);
      // sessionStorage.setItem('message',
      //   JSON.stringify({
      //     type: 'success',
      //     content: `Welcome to ${this.whitelabel.title} ${client.name}. You may search for available time-slots now. See you soon.`}));

      this.router.navigateByUrl(this.returnUrl);
    }, error => {
      this.loading = false;
      console.warn('error', error);
      this.error_message = error.error;
    }, () => {
      this.loading = false;
    });
  }

  ngAfterViewChecked(): void {
    if (!document.getElementById('mfa_holder')) {
      return;
    }
    if (document.getElementsByName('mbvt_wave_msisdn').length > 0) {
      return;
    }
    if (sessionStorage.getItem('msisdn') && sessionStorage.getItem('signed')) {
      const msisdn = sessionStorage.getItem('msisdn');
      // @ts-ignore
      const masked = msisdn.replace(/^([0-9]{2})[0-9]{6}([0-9]+)/g, '$1xxxxxx$2');
      const signed = sessionStorage.getItem('signed');
      postscribe('#mfa_holder',
        `<input type="hidden" name="mbvt_wave_msisdn" value="${msisdn}">
<input type="hidden" name="mbvt_wave_signed_msisdn" value="${signed}">
<div class="alert alert-info">Log-in as ${masked}?<br /><small>Not you? <a id="clearSessionBtn">Click here!</a></small></div>`);
      // @ts-ignore
      document.getElementById('clearSessionBtn').addEventListener('click', this.clearSession.bind(this));
      setTimeout(function() {
        document.dispatchEvent((new Event('mbvt_mfa_ready')));
      }, 0);

    } else {
      if (!document.getElementById('mobivateMFAScript') && document.getElementById('mfa_holder')) {
        if (this.whitelabel.mfaid) {
          postscribe('#mfa_holder',
            `<script id="mobivateMFAScript" src="https://api-wave.mobivate.com/2fa/${this.whitelabel.mfaid}/hosted.js"></script>`);
        } else {
          postscribe('#mfa_holder',
            `<div class="alert alert-danger">Unfortunately the page is not configured correctly. Please contact us and let us know!</div>`);
        }

      }
    }
  }

  clearSession(e: Event): void {
    sessionStorage.clear();
    window.location.reload(true);
  }
}
