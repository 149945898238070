
    <div mat-dialog-title>
      <h4 class="modal-title">{{whitelabel.ask_home_address_label}}</h4>
    </div>
    <div mat-dialog-content>
      <form [formGroup]="form">
        <div class="form-group-lg">
          <mat-form-field style="width:100%">
            <input matInput formControlName="address" placeholder="Street address and number" type="text">
          </mat-form-field>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-8">
              <mat-form-field style="width:100%">
                <input matInput formControlName="country" placeholder="Country" type="text" [matAutocomplete]="countries">
                <mat-autocomplete #countries="matAutocomplete">
                  <mat-option *ngFor="let option of filteredCountries | async" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4">
              <mat-form-field style="width:100%">
                <input matInput formControlName="postcode" placeholder="Postcode" type="text">
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
      <ng-container *ngIf="locations.length > 0">
        <mat-divider></mat-divider>
        <mat-form-field style="width:100%">
          <mat-label>Please select a location</mat-label>
          <mat-select [formControl]="selected">
            <mat-option *ngFor="let o of locations" [value]="o">
              - {{o.street}}, {{o.postalCode}}, {{o.adminArea5}} , {{o.adminArea4}}, {{o.adminArea3}}, {{o.adminArea1}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="primary" type="button" (click)="lookup()" *ngIf="!locations || locations.length === 0">Search</button>
      <button mat-raised-button color="warn" type="button" (click)="save()" *ngIf="locations.length > 0" [disabled]="selected.invalid">Select
      </button>
      <span style="flex: 1 1 auto"></span>
      <button mat-flat-button type="button" (click)="close()">Cancel</button>
    </div>
  