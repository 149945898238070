/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./loading/loading.component.ngfactory";
import * as i2 from "./loading/loading.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./app.component";
import * as i6 from "./_services/alert.service";
import * as i7 from "./_providers/global-settings";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/common/http";
import * as i10 from "./_services/api.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-dialog"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-loading", [], null, null, null, i1.View_LoadingComponent_0, i1.RenderType_LoadingComponent)), i0.ɵdid(2, 49152, null, 0, i2.LoadingComponent, [], null, null), (_l()(), i0.ɵted(-1, null, [" Page loading, please wait! "]))], null, null); }
function View_AppComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "link", [["id", "whitelabelCss"], ["rel", "stylesheet"]], [[8, "href", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.whitelabel.css; _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(7, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.whitelabel; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.whitelabel && _co.whitelabel.css); _ck(_v, 3, 0, currVal_1); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i5.AppComponent, [i4.ActivatedRoute, i4.Router, i6.AlertService, i7.GlobalSettings, i8.DomSanitizer, i9.HttpClient, i10.SettingsResource], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
