import { BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalSettings } from './_providers';
import { AppComponent } from './app.component';
import { LoginComponent } from './login';
import { MainComponent } from './main';
import { routing } from './app.routing';
import {
  // ConfirmComponent,
  // ModalComponent,
  AlertComponent,
  KeysPipe,
  AddressComponent,
  FilterPipe
} from './_directives';
import { AuthGuard } from './_guards';
import {
  AddressResource,
  AlertService,
  AppointmentResource,
  CalendarsResource,
  ClientResource,
  MarkdownService,
  SettingsResource
} from './_services';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import {BootstrapModalModule} from 'ng2-bootstrap-modal';
import { ClientComponent } from './client/client.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarClientComponent } from './calendar-client/calendar-client.component';
import { EventComponent } from './event/event.component';
import {LogoutComponent} from './logout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateFormatAdapter } from './_adapters/dateformat.adapter';
import {MatSelectCountryModule} from '@angular-material-extensions/select-country';
import { LoadingComponent } from './loading/loading.component';

import {
  DateAdapter,
  MatAutocompleteModule,
  MatButtonModule,
  MatDatepickerModule, MatDividerModule,
  MatIconModule,
  MatInputModule, MatNativeDateModule,
  MatSelectModule, MatStepperModule
} from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    MainComponent,
    AlertComponent,
    // ConfirmComponent,
    // ModalComponent,
    ClientComponent,
    CalendarComponent,
    CalendarClientComponent,
    EventComponent,
    KeysPipe,
    FilterPipe,
    LoadingComponent,
    AddressComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    routing,
    Ng2AutoCompleteModule,
    BootstrapModalModule.forRoot({container: document.body}),
    BrowserAnimationsModule,

// Material
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatDialogModule,
    MatSelectCountryModule,
    MatDividerModule

  ],
  entryComponents: [
    // ConfirmComponent,
    // ModalComponent
    AlertComponent,
    AddressComponent,
  ],
  providers: [
    AuthGuard,
    FormBuilder,
    AlertService,
    GlobalSettings,
    MarkdownService,
    SettingsResource,
    AddressResource,
    ClientResource,
    AppointmentResource,
    CalendarsResource,
    MatDatepickerModule,
    { provide: DateAdapter, useClass: DateFormatAdapter },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
