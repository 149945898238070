
    <div class="modal" style="display:block; top:5%; position:absolute;" id="loginModal" *ngIf="whitelabel && whitelabel.id">

      <form *ngIf="!show_registration" class="form" name="login" (ngSubmit)="login.form.valid && getClient()" #login="ngForm" novalidate autocomplete="off">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="text-center" style="margin-bottom:20px;">
                <img src="{{whitelabel.logo}}" alt="{{whitelabel.title}}" style="max-height:200px; max-width:100%;">
              </div>
              <alert></alert>

              <p *ngIf="whitelabel.intro_body && !message" class="text-center">{{whitelabel.intro_body}}</p>
              <div id="mfa_holder"></div>
              <app-loading *ngIf="loading" ></app-loading>
            </div>
          </div>
        </div>
      </form>

      <form *ngIf="show_registration" class="form" name="registration" (ngSubmit)="registration.form.valid && registerClient()" #registration="ngForm" novalidate autocomplete="off">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="text-center" style="margin-bottom:20px;">
                <img src="{{whitelabel.logo}}" title="{{whitelabel.title}}" style="max-height:200px; max-width:100%;">
              </div>
              <h4>Registration</h4>

              <div class="form-group-xl mt-2">
                <label>Your Name</label>
                <input type="text" class="form-control" name="name" [(ngModel)]="model.name" #name="ngModel" required
                       placeholder="Full Name">
                <div *ngIf="registration.submitted && !name.valid" class="help-block">Please tell us your name</div>
              </div>

              <div class="form-group-xl mt-2">
                <label>Your Email Address</label>
                <input type="email" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel" required
                       placeholder="Email Address" autocomplete="off">
                <div *ngIf="registration.submitted && !email.valid" class="help-block">Enter your email address!</div>
              </div>

              <div class="form-group-xl mt-2">
                <label>Your Phone Number</label>
                <div class="form-control">{{model.mbvt_wave_msisdn}}</div>
              </div>

              <div *ngIf="error_message" class="help-block text-danger">{{error_message}}</div>
            </div>

            <div class="modal-footer">

              <button [disabled]="registration['$invalid']" *ngIf="!loading" class="btn btn-primary">
                <span>Register</span>
              </button>
              <app-loading *ngIf="loading"></app-loading>
            </div>
          </div>
        </div>
      </form>

      <div *ngIf="show_forbidden" class="alert alert-danger">
        <h3>Sorry</h3>
        <p>Account was not found and this system does not accept new registrations. Please call us if you like to make a booking.</p>
      </div>


    </div>
  