import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {GlobalSettings} from '../_providers';
import {AlertService, ClientResource, MarkdownService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-calendar-client',
  template: `
    <div class="content-wrapper">
      <div class="container">
        <alert></alert>
      </div>
    </div>`,
})
export class CalendarClientComponent implements OnInit, AfterViewInit {
  whitelabel: any;

  constructor(
    private route: ActivatedRoute,
    private globalSettings: GlobalSettings,
    private alertService: AlertService,
    private router: Router,
    private builder: FormBuilder,
    private sanitizer: DomSanitizer,
    private md: MarkdownService,
    private client: ClientResource,
  ) {
    route.params.subscribe((params: Params) => {
      sessionStorage.setItem('calendar', params['calendarId']);
      client.get_data({client: params['clientId']}).subscribe( data => {
        sessionStorage.setItem('name', data.name);
        sessionStorage.setItem('msisdn', data.msisdn);
        globalSettings.setUser(data.id);
        sessionStorage.setItem('message', JSON.stringify({type: 'success', content: `Hi ${data.name}`}));
        router.navigateByUrl('/');
      }, error => {
        alertService.error(error.error);
      })
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

}
