import {Component, OnInit} from '@angular/core';
import { GlobalSettings } from './_providers';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService, Settings, SettingsResource} from './_services';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import postscribe from 'postscribe';


@Component({
  moduleId: module.id,
  selector: 'app-root',
  template: `
    <div class="modal-dialog" *ngIf="!whitelabel">
      <app-loading></app-loading>
      Page loading, please wait!
    </div>
    <link *ngIf="whitelabel && whitelabel.css" [href]="whitelabel.css" rel="stylesheet" id="whitelabelCss" />

    <div class="content">
      <div class="container">
        <router-outlet></router-outlet>
      </div>
    </div>
  `
})

export class AppComponent implements OnInit {
  whitelabel: Settings;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private globalSettings: GlobalSettings,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private settings: SettingsResource
  ) {
    this.settings.get().subscribe((config: Settings) => {
      this.whitelabel = config;
      if (!this.whitelabel.hasOwnProperty('client')) {
        this.whitelabel.client = null;
      }
      if (!this.whitelabel.hasOwnProperty('allow_registrations')) {
        this.whitelabel.allow_registrations = true;
      }
      if (typeof this.whitelabel.css === 'string') {
        this.whitelabel.css = this.sanitizer.bypassSecurityTrustResourceUrl(
          '/assets/themes/' + this.whitelabel.css.toLowerCase() + '.css');
      }

      this.globalSettings.setWhitelabel(this.whitelabel);
    }, err => {
      console.warn(err);
      postscribe('.container',
        `<div class="alert alert-danger">Unfortunately the page is not configured correctly. Please contact us and let us know!</div>`);

    });
    this.globalSettings.setUser(sessionStorage.getItem('currentAppointmentUser'));
  }

  getUser(): any {
    return this.globalSettings.user$;
  }

  ngOnInit() {

  }


}
